<template>
  <section class="page page-auth">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block-auth">
            <h2>Регистрация</h2>
            <form class="auth-form" @submit.prevent="submitHandler">
              <div class="form-group">
                <input
                  v-model.trim="v$.username.$model"
                  class="form-control"
                  name="login"
                  placeholder="Логин"
                  type="text"
                />
                <small
                  class="invalidUserData"
                  v-if="v$.username.$dirty && v$.username.minLength.$invalid"
                >
                  Длина логина должна быть от
                  {{ v$.username.minLength.$params.min }} символов
                </small>
                <small
                  class="invalidUserData"
                  v-if="v$.username.$dirty && v$.username.maxLength.$invalid"
                >
                  Длина логина должна быть не более
                  {{ v$.username.maxLength.$params.max }} символов
                </small>
                <small
                  class="invalidUserData"
                  v-if="v$.username.$dirty && v$.username.isValidLogin.$invalid"
                >
                  Логин может содержать только буквы латинского алфавита (a–z),
                  цифры (0–9) и символы (!_$@)
                </small>
              </div>
              <div class="form-group">
                <input
                  v-model.trim="v$.email.$model"
                  class="form-control"
                  name="login"
                  placeholder="Email"
                  type="text"
                />
                <small
                  class="invalidUserData"
                  v-if="v$.email.$dirty && v$.email.email.$invalid"
                >
                  Поле должно быть заполнено валидным email адресом
                </small>
              </div>
              <div class="form-group">
                <input
                  v-model.trim="v$.password.$model"
                  class="form-control"
                  name="password"
                  placeholder="Пароль"
                  type="password"
                />
                <small
                  class="invalidUserData"
                  v-if="v$.password.$dirty && v$.password.minLength.$invalid"
                >
                  Длина пароля должна быть от
                  {{ v$.password.minLength.$params.min }} символов
                </small>
                <small
                  class="invalidUserData"
                  v-if="v$.password.$dirty && v$.password.maxLength.$invalid"
                >
                  Длина пароля должна быть не более
                  {{ v$.password.maxLength.$params.max }} символов
                </small>
              </div>
              <div class="bottom">
                <div class="offer">
                  <input
                    type="checkbox"
                    class="checkbox"
                    id="accept-offer"
                    v-model="offerAccepted"
                  />
                  <label for="accept-offer"
                    >Я прочитал и принимаю условия
                    <router-link to="/offer"
                      >пользовательского соглашения
                    </router-link>
                  </label>
                </div>
                <button class="button">Создать аккаунт</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { email, maxLength, minLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { NotificationService } from "@/services";

export default {
  name: "Register",
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    username: "",
    email: "",
    password: "",
    offerAccepted: false
  }),
  validations: {
    username: {
      required,
      isValidLogin(login) {
        const regex = /^[a-zA-Z0-9!_$@]+$/;
        return regex.test(login);
      },

      minLength: minLength(3),
      maxLength: maxLength(32)
    },
    email: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(32),
      email
    },
    password: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(64)
    }
  },
  methods: {
    async submitHandler() {
      if (!this.offerAccepted) {
        NotificationService.error(
          "Принятие условий пользовательского соглашения является обязательным требованием процесса регистрации"
        );
        return;
      }

      if (this.v$.$invalid) {
        this.v$.$touch();
        return;
      }

      let formData = {
        username: this.username,
        name: this.name,
        email: this.email,
        password: this.password
      };

      try {
        await this.$store.dispatch("register", formData);
        await this.$store.dispatch("login", formData);
        await this.$router.push("/");
      } catch (e) {
        // nop
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/page-auth.scss";
</style>
